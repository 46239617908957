import * as React from "react"
import { graphql } from 'gatsby'

import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft  } from "@fortawesome/free-solid-svg-icons"

import "@fontsource/source-sans-pro"

import "../styles/custom.sass"
import "../styles/modal.css"

import FooterSection from "../components/footer-section"
import LogoSection from "../components/logo-section"
import AppButtonSection from "../components/app-button-section"
import HeaderSection from "../components/header-section"

import { PageHead } from "../components/page-head"

const IndexPage = ({data}) => {
  return (
    <div>
      <HeaderSection />
      <main className="container-fluid mx-0 px-0 bg-gradient pt-1 pt-md-5">
        <section
          className="container px-3 mx-auto mt-4 mt-md-3 pt-2 pb-5 bg-image position-relative d-block"
          style={{ minHeight: "35vh" }}
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <h1 className="h1 d-inline text-light">
                  Mariners Medico Guide
                </h1>
                <p className="mt-4 text-light">
                  You have free access to the Mariners Medico Guide, approved by
                  the Norwegian Flag State as its equivalent national medical
                  guide to the WHO International Medical Guide for Ships.
                  Developed by Gard and the Norwegian Centre for Maritime and
                  Diving Medicine to improve health protection and medical care
                  of seafarers onboard ships.
                </p>
                <p className="mt-4 text-light">
                  The Mariners Medico Guide offers up-to-date, fast access and
                  easy-to-use medical guidance. A comprehensive practical
                  symptom-based approach, designed and verified by doctors
                  specialised in maritime medicine, delivering guidance
                  step-by-step, advising when and how to get expert medical
                  advice when needed.
                </p>
                <p className="mb-5 mt-2 text-light">
                  Download the app directly onto your mobile device or PC to
                  ensure you have full access even when offline. Make sure to be
                  in an area with good mobile coverage when first downloading
                  (approximately 100 MB of content).
                </p>
              </div>
            </div>
          </div>
        </section>

        <AppButtonSection />

        <section
          className="container-fluid mx-0 px-0 py-3"
          style={{ backgroundColor: "#f2f2f2" }}
        >
          <div className="container px-0 mx-auto my-5">
            <div className="row mx-0 d-flex flex-column flex-lg-row px-3 px-md-3">

              <div className="col col-lg-4 mb-4 d-lg-flex justify-content-lg-start">
                <div className="medico-quote card shadow border-0 mb-3">
                  <div className="row g-0">
                    <div className="col-auto mb-0 px-0 col-md-4 col-lg-auto col-xl-auto mx-0 position-relative">
                      <StaticImage
                        src="../images/RolfThoreRoppestad_FotoMonaHauglid-5566.JPG"
                        alt="Rolf Thore Roppestad"
                        aspectRatio={16 / 10}
                        placeholder="blurry"
                        imgClassName="card-img-top"
                        className="h-100 border-0 m-0"
                      />
                      <span
                        className="position-absolute d-none d-md-flex d-lg-none d-xl-none justify-content-center align-items-center ms-4 rounded-circle text-white"
                        style={{
                          right: "-17px",
                          top: "29px",
                          height: "35px",
                          width: "35px",
                          background: "#0F978A",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faQuoteLeft}
                          className="fa-fw"
                          style={{ color: "#FFF", fontSize: ".8em" }}
                        />
                      </span>
                    </div>
                    <div className="col p-0 m-0 col-md-8 col-lg-auto d-flex justify-content-center">
                      <div className="row g-0 px-0">
                        <div className="card-body pt-0 px-0 position-relative">
                          <span
                            className="position-absolute m-0 d-flex d-md-none d-lg-flex justify-content-center align-items-center ms-4 rounded-circle text-white"
                            style={{
                              top: "-13px",
                              height: "35px",
                              width: "35px",
                              background: "#0F978A",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faQuoteLeft}
                              className="fa-fw"
                              style={{ color: "#FFF", fontSize: ".8em" }}
                            />
                          </span>
                          <div className="col d-block d-md-none d-lg-block d-xl-block">
                            <div className="bg-gradient-horz p-1"></div>
                          </div>
                          <p className="card-text px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pt-4 pt-md-4 mt-md-2 fst-italic text-secondary">
                            I personally believe this App shows how seriously we
                            value the health and wellbeing of seafarers. We are
                            ensuring they have access to up-to-date medical
                            support as quickly as possible when needed.
                          </p>
                        </div>
                        <div className="card-footer mt-auto px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pb-3 bg-transparent border-0">
                            <h5 className="card-title">Rolf Thore Roppestad</h5>
                            <h6 className="card-subtitle mb-2 fst-italic text-muted">
                              Chief Executive Officer
                              <br />
                              <strong>Gard</strong>
                            </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col d-none d-md-block d-lg-none">
                      <div className="bg-gradient-horz p-1 rounded-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col col-lg-4 mb-4 d-lg-flex justify-content-lg-center">
                <div className="medico-quote card shadow border-0 mb-3">
                  <div className="row g-0">
                    <div className="col-auto mb-0 px-0 col-md-4 col-lg-auto col-xl-auto mx-0 position-relative">
                      <StaticImage
                        src="../images/EivindHansenHaukeland.jpg"
                        alt="Eivind Hansen"
                        aspectRatio={16 / 10}
                        placeholder="blurry"
                        imgClassName="card-img-top"
                        className="h-100 border-0 m-0"
                      />
                      <span
                        className="position-absolute d-none d-md-flex d-lg-none d-xl-none justify-content-center align-items-center ms-4 rounded-circle text-white"
                        style={{
                          right: "-17px",
                          top: "29px",
                          height: "35px",
                          width: "35px",
                          background: "#0F978A",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faQuoteLeft}
                          className="fa-fw"
                          style={{ color: "#FFF", fontSize: ".8em" }}
                        />
                      </span>
                    </div>
                    <div className="col p-0 m-0 col-md-8 col-lg-auto">
                      <div className="row g-0 px-0">
                        <div className="card-body pt-0 px-0 position-relative">
                          <span
                            className="position-absolute m-0 d-flex d-md-none d-lg-flex justify-content-center align-items-center ms-4 rounded-circle text-white"
                            style={{
                              top: "-13px",
                              height: "35px",
                              width: "35px",
                              background: "#0F978A",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faQuoteLeft}
                              className="fa-fw"
                              style={{ color: "#FFF", fontSize: ".8em" }}
                            />
                          </span>
                          <div className="col d-block d-md-none d-lg-block d-xl-block">
                            <div className="bg-gradient-horz p-1"></div>
                          </div>
                          <p className="card-text mb-auto px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pt-4 pt-md-4 mt-md-2 fst-italic text-secondary">
                            Haukeland University Hospital is strongly committed
                            to health at sea. This app complements our
                            telemedical services at sea and facilitates better
                            access to basic health care for all seafarers.
                          </p>
                        </div>
                        <div className="card-footer px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pb-3 bg-transparent border-0">
                            <h5 className="card-title">Eivind Hansen</h5>
                            <h6 className="card-subtitle mb-2 fst-italic text-muted">
                              Chief Executive Officer
                              <br />
                              <strong>Haukeland University Hospital</strong>
                            </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col d-none d-md-block d-lg-none">
                      <div className="bg-gradient-horz p-1 rounded-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col col-lg-4 mb-4 d-lg-flex justify-content-lg-end">
                <div className="medico-quote card shadow border-0 mb-3">
                  <div className="row g-0">
                    <div className="col-auto mb-0 px-0 col-md-4 col-lg-auto col-xl-auto mx-0 position-relative">
                      <StaticImage
                        src="../images/KnutArildHareide10.jpg"
                        alt="Knut Arild Hareide"
                        aspectRatio={16 / 10}
                        placeholder="blurry"
                        imgClassName="card-img-top"
                        className="h-100 border-0 m-0"
                      />
                      <span
                        className="position-absolute d-none d-md-flex d-lg-none d-xl-none justify-content-center align-items-center ms-4 rounded-circle text-white"
                        style={{
                          right: "-17px",
                          top: "29px",
                          height: "35px",
                          width: "35px",
                          background: "#0F978A",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faQuoteLeft}
                          className="fa-fw"
                          style={{ color: "#FFF", fontSize: ".8em" }}
                        />
                      </span>
                    </div>
                    <div className="col p-0 m-0 col-md-8 col-lg-auto">
                      <div className="row g-0 px-0">
                        <div className="card-body pt-0 px-0 position-relative">
                          <span
                            className="position-absolute m-0 d-flex d-md-none d-lg-flex justify-content-center align-items-center ms-4 rounded-circle text-white"
                            style={{
                              top: "-13px",
                              height: "35px",
                              width: "35px",
                              background: "#0F978A",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faQuoteLeft}
                              className="fa-fw"
                              style={{ color: "#FFF", fontSize: ".8em" }}
                            />
                          </span>
                          <div className="col d-block d-md-none d-lg-block d-xl-block">
                            <div className="bg-gradient-horz p-1"></div>
                          </div>
                          <p className="card-text px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pt-4 pt-md-4 mt-md-2 fst-italic text-secondary">
                            Since 1923, Radio Medico has improved the safety and
                            security for seafarers all over the world. It is very
                            positive that access for seafarers to medical guidance on
                            board will be strengthened with an app.
                          </p>
                        </div>
                        <div className="card-footer mt-auto px-4 ms-1 ms-md-2 ms-lg-0 ms-xl-0 pb-3 bg-transparent border-0">
                            <h5 className="card-title">Knut Arild Hareide</h5>
                            <h6 className="card-subtitle mb-2 fst-italic text-muted">
                              Director General
                              <br />
                              <strong>Norwegian Maritime Authority</strong>
                            </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col d-none d-md-block d-lg-none">
                      <div className="bg-gradient-horz p-1 rounded-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <LogoSection />
      </main>
      <FooterSection currentYear={new Date().getFullYear()} />
    </div>
  );
};

export default IndexPage;

export const query  = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`

export const Head = ({location, data}) => (
  <>
    <PageHead pathname={location.pathname}>
      <meta name="description" content={data.site.siteMetadata.description} />
    </PageHead>
  </>
)